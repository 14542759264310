<template>
    <div class="grid grid-nogutter w-full">
        <div class="col-12 md:col-12 lg:col-4 p-2">
            <p  class="text-left text-color-secondary m-0 my-1">organization selector</p>
            <p-dropdown
                class="w-full"
                v-model="selectedOrganization"
                editable
                filter
                :loading="orgaStore.isLoading"
                :options="orgaStore.getLoadedOrganisations"
                :disabled="orgaStore.isLoading || orgaStore.getLoadedOrganisations < 1"
                optionLabel="accountName"
                placeholder="Select an Organisation"
            ></p-dropdown>
        </div>
        <div class="col-12 md:col-6 lg:col-4 p-2">
            <p  class="text-left text-color-secondary m-0 my-1">project selector</p>
            <p-multiselect
                class="w-full"
                v-model="selectedProjects"
                editable
                filter
                :loading="projectStore.isLoading"
                :options="projectStore.getLoadedProjects"
                :disabled="projectStore.isLoading || projectStore.getLoadedProjects < 1"
                optionLabel="name"
                placeholder="Select projects"
                display="chip"
            ></p-multiselect>
        </div>
        <div class="col-12 md:col-6 lg:col-4 p-2">
            <p class="text-left text-color-secondary m-0 my-1">variable group selector</p>
            <p-multiselect
                class="w-full"
                v-model="selectedVariableGroups"
                editable
                filter
                :loading="varGroupStore.isLoading"
                :options="varGroupStore.getLoadedVariableGroupsByProjects"
                :disabled="varGroupStore.isLoading || varGroupStore.getLoadedVariableGroupsByProjects < 1"
                optionLabel="name"
                optionGroupLabel="projectName"
                optionGroupChildren="variableGroups"
                placeholder="Select variable groups"
                display="chip"
            >
                <template #optiongroup="slotProps">
                    <div class="flex align-items-center">
                        <i class="pi pi-book"></i>
                        <span>{{ slotProps.option.projectName }}</span>
                    </div>
                </template>
            </p-multiselect>
        </div>
    </div>
</template>

<script>
import { useOrganisationStore } from '@/store/organisation.store'
import { useProjectStore } from '@/store/project.store'
import { useVariableGroupStore } from '@/store/variableGroup.store'
import { useVariableStore } from '@/store/variable.store'

export default {
    name: 'LibrariesSelector',
    setup() {
        return {
            orgaStore: useOrganisationStore(),
            projectStore: useProjectStore(),
            varGroupStore: useVariableGroupStore(),
            varStore: useVariableStore(),
        }
    },
    created() {
        this.orgaStore.fetchOrganisations()
    },
    computed: {
        selectedOrganization: {
            get() {
                return this.orgaStore.getSelectedOrganisation
            },
            set(organisation) {
                this.orgaStore.setSelectedOrganisation(organisation)
                this.projectStore.fetchProjects(this.selectedOrganization.accountName)
            },
        },
        selectedProjects: {
            get() {
                return this.projectStore.getSelectedProjects
            },
            set(projects) {
                this.projectStore.setSelectedProjects(projects)
                this.varGroupStore.fetchVariableGroups(this.selectedOrganization , this.selectedProjects)
                this.varStore.fetchAllVariablesByVariableGroup(this.selectedVariableGroups, this.selectedOrganization.accountName)
            }
        },
        selectedVariableGroups: {
            get() {
                return this.varGroupStore.getSelectedVariableGroups
            },
            set(variableGroups) {
                this.varGroupStore.setSelectedProjects(variableGroups)
                this.varStore.fetchAllVariablesByVariableGroup(this.selectedVariableGroups, this.selectedOrganization.accountName)
            }
        }
    }
}

</script>