<template>
    <div
        class="w-full"
    >
        <div v-if="!isEditing">
            <i class="pi pi-plus-circle cursor-pointer" @click="startAddingVariable"></i>
        </div>
        <div v-else>
            <div class="flex flex-1 justify-content-evenly">
                <div>
                    <i class="pi pi-lock cursor-pointer" @click="switchSecretState" v-if="isSecret"></i>
                    <i class="pi pi-lock-open cursor-pointer" @click="switchSecretState" v-else></i>  
                </div>
                <div>
                    <i class="pi pi-check text-green-400 cursor-pointer" @click="confirmAddingVariable"></i>
                    <i class="pi pi-times text-red-400 cursor-pointer" @click="cancelAddingVariable"></i>
                </div>
            </div>
            <div>
                <p-inputtext
                    v-model="value"
                    placeholder="text"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { useVariableStore } from '@/store/variable.store'
import { useOrganisationStore } from '@/store/organisation.store'

export default {
    name: "AddVariableCell",
    props: {
        variableGroupProjectReferences: Object,
        variableKey: String,
    },
    setup() {
        return {
            orgStore: useOrganisationStore(),
            varStore: useVariableStore(),
        }
    },
    data() {
        return({
            isEditing: false,
            value: "",
            isSecret: false,
            isReadOnly: false
        })
    },
    methods: {
        startAddingVariable() {
            this.isEditing = true
        },
        switchSecretState() {
            this.isSecret = !this.isSecret
        },
        switchReadOnlyState() {
            this.isReadOnly = ! this.isReadOnly
        },
        cancelAddingVariable() {
            this.isEditing = false
            this.value = ""
            this.isSecret = false
            this.isReadOnly = false
        },
        confirmAddingVariable() {
            if(this.variableKey.length < 1) {
                this.$toast.add({ severity: 'error', summary: 'Error Message', detail: 'Key cannot be empty', life: 3000 })
                return
            }
            if(this.isVariableKeyAlreadyUsed) {
                this.$toast.add({ severity: 'error', summary: 'Error Message', detail: 'Key already exists', life: 3000 })
                return
            }
            this.$confirm.require({
                message: 'Are you sure you want to add the variable ' + this.variableKey + ' to the group of variable ' + this.variableGroupProjectReferences[0].name + ' ?' ,
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.varStore.updateCreateVariableToVariableGroup( this.formatedVariable(), this.variableGroupProjectReferences, this.orgStore.getSelectedOrganisation.accountName)
                    .then(
                        () => {
                            this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Message Content', life: 3000 })
                            this.cancelAddingVariable()
                        }
                    )
                    .catch(
                        () => this.$toast.add({ severity: 'error', summary: 'Error Message', detail: 'Message Content', life: 3000 })
                    )
                },
                reject: () => {
                    this.cancelAddingVariable()
                }
            })
        },
        formatedVariable() {
            return(
                {
                    key: this.variableKey,
                    value: this.value,
                    isEditing: this.isEditing,
                    isSecret: this.isSecret
                }
            )
        }
    },
    computed: {
        isVariableKeyAlreadyUsed() {
            let variablieGroup =  this.varStore.getVariableOfVariableGroups.find(variablieGroup => variablieGroup.variableGroupProjectReferences == this.variableGroupProjectReferences)
            if(!variablieGroup) {
                return true // TO DO: Implement Error Message
            }
            return undefined != variablieGroup.variables.find(variable => variable.key == this.variableKey)
        }
    }
}

</script>