<template>
    <div
        class="p-1"
    >
        <p-inputtext
            :value="value"
            @input="$emit('update:value', $event.target.value)"
            type="text"
            placeholder="Search variable"
            :class="{'p-invalid': !valid}"
                    />
    </div>
</template>

<script>
export default {
    name: "SearchCell",
    props: {
        value: String,
        placeholder: String,
        valid: Boolean
    },
    emits: ['update:value']
}
</script>