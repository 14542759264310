<template>
    <div class="flex h-full w-full justify-content-center cursor-pointer transition-duration-500" @click="openSwagger()">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 500 500" width="100%" height="100%" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g>
                <g><path class="st0" d="M 232.5,56.5 C 315.718,53.3442 378.218,87.3442 420,158.5C 451.558,223.54 449.558,287.54 414,350.5C 369.11,417.19 306.277,447.356 225.5,441C 152.849,429.316 101.349,390.149 71,323.5C 40.9734,239.947 57.4734,167.114 120.5,105C 152.762,77.0807 190.095,60.9141 232.5,56.5 Z"/></g>
                <g><path class="st1" d="M 301.5,122.5 C 305.808,121.735 308.642,123.402 310,127.5C 320.401,162.869 330.901,198.202 341.5,233.5C 327.981,238.562 314.314,243.229 300.5,247.5C 299.029,246.619 298.196,245.286 298,243.5C 287.733,207.932 277.233,172.432 266.5,137C 267.487,134.679 269.154,133.013 271.5,132C 281.713,129.121 291.713,125.954 301.5,122.5 Z"/></g>
                <g><path class="st1" d="M 158.5,125.5 C 170.838,125.333 183.171,125.5 195.5,126C 196.931,126.465 198.097,127.299 199,128.5C 199.5,141.829 199.667,155.163 199.5,168.5C 184.833,168.5 170.167,168.5 155.5,168.5C 155.333,155.163 155.5,141.829 156,128.5C 157.045,127.627 157.878,126.627 158.5,125.5 Z"/></g>
                <g><path class="st1" d="M 215.5,125.5 C 228.599,125.038 241.599,125.538 254.5,127C 255,127.5 255.5,128 256,128.5C 256.5,138.828 256.666,149.161 256.5,159.5C 241.833,159.5 227.167,159.5 212.5,159.5C 212.334,149.161 212.5,138.828 213,128.5C 214.045,127.627 214.878,126.627 215.5,125.5 Z"/></g>
                <g><path class="st1" d="M 212.5,168.5 C 227.167,168.5 241.833,168.5 256.5,168.5C 256.5,178.167 256.5,187.833 256.5,197.5C 241.833,197.5 227.167,197.5 212.5,197.5C 212.5,187.833 212.5,178.167 212.5,168.5 Z"/></g>
                <g><path class="st1" d="M 155.5,197.5 C 170.167,197.5 184.833,197.5 199.5,197.5C 199.667,225.502 199.5,253.502 199,281.5C 198.167,282.333 197.333,283.167 196.5,284C 183.833,284.667 171.167,284.667 158.5,284C 157.333,283.5 156.5,282.667 156,281.5C 155.5,253.502 155.333,225.502 155.5,197.5 Z"/></g>
                <g><path class="st0" d="M 256.5,207.5 C 241.833,207.5 227.167,207.5 212.5,207.5C 212.5,231.833 212.5,256.167 212.5,280.5C 211.502,256.006 211.169,231.339 211.5,206.5C 226.676,206.171 241.676,206.504 256.5,207.5 Z"/></g>
                <g><path class="st1" d="M 256.5,207.5 C 256.667,232.169 256.5,256.836 256,281.5C 255.167,282.333 254.333,283.167 253.5,284C 240.5,284.667 227.5,284.667 214.5,284C 213.177,283.184 212.511,282.017 212.5,280.5C 212.5,256.167 212.5,231.833 212.5,207.5C 227.167,207.5 241.833,207.5 256.5,207.5 Z"/></g>
                <g><path class="st1" d="M 341.5,243.5 C 342.5,243.5 343.5,243.5 344.5,243.5C 346.507,251.192 348.674,258.859 351,266.5C 352.379,270.475 351.213,273.309 347.5,275C 336.529,278.268 325.529,281.435 314.5,284.5C 311.832,284.255 309.998,282.921 309,280.5C 306.833,273.167 304.667,265.833 302.5,258.5C 302.833,257.833 303.167,257.167 303.5,256.5C 316.275,252.131 328.942,247.798 341.5,243.5 Z"/></g>
                <g><path class="st1" d="M 156.5,292.5 C 221.168,292.333 285.834,292.5 350.5,293C 351.139,295.411 351.473,297.911 351.5,300.5C 351.426,303.287 350.76,305.787 349.5,308C 285.167,308.667 220.833,308.667 156.5,308C 155.19,302.834 155.19,297.667 156.5,292.5 Z"/></g>
                <g><path class="st1" d="M 216.5,327.5 C 218.833,327.5 221.167,327.5 223.5,327.5C 223.5,329.5 223.5,331.5 223.5,333.5C 221.167,333.5 218.833,333.5 216.5,333.5C 216.5,331.5 216.5,329.5 216.5,327.5 Z"/></g>
                <g><path class="st1" d="M 260.5,327.5 C 262.833,327.5 265.167,327.5 267.5,327.5C 267.174,331.884 267.508,336.217 268.5,340.5C 275.327,335.879 281.16,336.879 286,343.5C 288.544,350.983 287.378,357.817 282.5,364C 277.312,366.349 272.478,365.849 268,362.5C 267.517,363.448 267.351,364.448 267.5,365.5C 265.167,365.5 262.833,365.5 260.5,365.5C 260.5,352.833 260.5,340.167 260.5,327.5 Z"/></g>
                <g><path class="st1" d="M 293.5,327.5 C 295.833,327.5 298.167,327.5 300.5,327.5C 300.5,340.167 300.5,352.833 300.5,365.5C 298.167,365.5 295.833,365.5 293.5,365.5C 293.5,352.833 293.5,340.167 293.5,327.5 Z"/></g>
                <g><path class="st0" d="M 154.5,338.5 C 156.693,337.203 159.027,337.203 161.5,338.5C 161.376,339.107 161.043,339.44 160.5,339.5C 158.735,338.539 156.735,338.205 154.5,338.5 Z"/></g>
                <g><path class="st1" d="M 170.5,337.5 C 176.352,336.861 181.852,337.861 187,340.5C 188.134,348.445 188.967,356.445 189.5,364.5C 186.493,365.834 183.659,365.501 181,363.5C 176.228,365.883 171.395,366.05 166.5,364C 164.571,360.744 164.071,357.244 165,353.5C 170.446,350.746 175.946,348.246 181.5,346C 180.803,343.472 179.136,342.306 176.5,342.5C 174.312,342.307 172.645,343.141 171.5,345C 169.527,345.495 167.527,345.662 165.5,345.5C 165.667,341.821 167.334,339.154 170.5,337.5 Z"/></g>
                <g><path class="st1" d="M 195.5,337.5 C 197.5,337.5 199.5,337.5 201.5,337.5C 201.367,342.272 202.7,342.438 205.5,338C 212.362,336.354 214.029,338.521 210.5,344.5C 206.895,342.694 204.395,343.694 203,347.5C 202.501,353.491 202.334,359.491 202.5,365.5C 200.167,365.5 197.833,365.5 195.5,365.5C 195.5,356.167 195.5,346.833 195.5,337.5 Z"/></g>
                <g><path class="st1" d="M 236.5,337.5 C 240.182,337.335 243.848,337.501 247.5,338C 249.329,338.273 250.829,339.107 252,340.5C 253.198,348.763 254.031,357.096 254.5,365.5C 252.167,365.5 249.833,365.5 247.5,365.5C 247.372,362.226 246.039,362.059 243.5,365C 238.16,366.534 233.66,365.367 230,361.5C 229.064,358.208 229.564,355.208 231.5,352.5C 235.947,350.338 240.613,348.671 245.5,347.5C 246.663,344.465 245.496,342.798 242,342.5C 239.851,342.658 238.017,343.492 236.5,345C 234.527,345.495 232.527,345.662 230.5,345.5C 230.642,341.367 232.642,338.7 236.5,337.5 Z"/></g>
                <g><path class="st1" d="M 314.5,337.5 C 325.983,336.649 331.649,341.983 331.5,353.5C 325.5,353.5 319.5,353.5 313.5,353.5C 313.549,356.099 314.549,358.266 316.5,360C 321.038,359.046 325.705,358.213 330.5,357.5C 330.531,361.597 328.531,364.097 324.5,365C 309.688,366.847 303.854,360.347 307,345.5C 308.353,341.65 310.853,338.983 314.5,337.5 Z"/></g>
                <g><path class="st1" d="M 345.5,337.5 C 360.762,336.703 366.595,343.703 363,358.5C 358.532,365.085 352.365,367.252 344.5,365C 336.126,359.24 334.293,351.74 339,342.5C 340.965,340.45 343.132,338.783 345.5,337.5 Z"/></g>
                <g><path class="st1" d="M 154.5,338.5 C 156.735,338.205 158.735,338.539 160.5,339.5C 157.456,347.925 154.123,356.258 150.5,364.5C 148.257,365.474 145.924,365.808 143.5,365.5C 140.167,356.167 136.833,346.833 133.5,337.5C 135.924,337.192 138.257,337.526 140.5,338.5C 142.962,344.386 145.129,350.386 147,356.5C 149.276,350.296 151.776,344.296 154.5,338.5 Z"/></g>
                <g><path class="st1" d="M 216.5,337.5 C 218.833,337.5 221.167,337.5 223.5,337.5C 223.5,346.833 223.5,356.167 223.5,365.5C 221.167,365.5 218.833,365.5 216.5,365.5C 216.5,356.167 216.5,346.833 216.5,337.5 Z"/></g>
                <g><path class="st0" d="M 271.5,342.5 C 278.891,343.093 281.725,347.093 280,354.5C 278.957,359.223 276.124,361.056 271.5,360C 266.208,354.168 266.208,348.335 271.5,342.5 Z"/></g>
                <g><path class="st0" d="M 316.5,342.5 C 321.713,342.087 324.38,344.42 324.5,349.5C 320.833,349.5 317.167,349.5 313.5,349.5C 313.688,346.794 314.688,344.461 316.5,342.5 Z"/></g>
                <g><path class="st0" d="M 347.5,343.5 C 356.631,344.071 359.464,348.737 356,357.5C 351.203,361.802 347.203,361.136 344,355.5C 342.593,350.599 343.76,346.599 347.5,343.5 Z"/></g>
                <g><path class="st0" d="M 177.5,352.5 C 178.833,352.5 180.167,352.5 181.5,352.5C 181.68,358.82 178.68,361.32 172.5,360C 171.065,357.733 171.398,355.733 173.5,354C 175.058,353.79 176.391,353.29 177.5,352.5 Z"/></g>
                <g><path class="st0" d="M 242.5,352.5 C 243.833,352.5 245.167,352.5 246.5,352.5C 247.003,359.162 244.003,361.662 237.5,360C 236.065,357.733 236.398,355.733 238.5,354C 240.058,353.79 241.391,353.29 242.5,352.5 Z"/></g>
            </g>
        </svg>
    </div>
</template>

<script>
import { appConfig } from '@/config/app';

export default {
    name: "logoVariableo",
    methods: {
        openSwagger() {
            window.open(appConfig.apiUrl + 'Swagger/', '_blank');
        }
    }
}
</script>

<style>
.st0{
    fill: var(--text-color);
    opacity:1
}

.st1{
    fill: var(--primary-color);
    opacity:1
}
</style>