import axios from "axios";
import AuthentificationService from "./AuthentificationService";
import { authentificationParameters } from "@/config";
import { appConfig } from "@/config/app";

const createInstance = (baseURL) => {
    let instance = axios.create({baseURL:baseURL,timeout:10000})
    return instance
}

class BackendService {
    constructor() {
        this.axiosInstance = createInstance(appConfig.apiUrl +  'api/')
        this.axiosInstance.interceptors.request.use(
            async config => {
                config.headers['Authorization'] = 'Bearer ' + await AuthentificationService.getToken(authentificationParameters) 
                config.headers['Content-Type'] = 'application/json'
                return config
            }
        )
    }

    async getAllOrganisations() {
        return this.axiosInstance.get('Organizations')
    }

    getProjectsByOrganisation(organization) {
        return this.axiosInstance.get('Organizations/' + organization).catch((e) => console.error(e))
    }

    getVariableGroupsByProject(organization, project) {
        return this.axiosInstance.get('Organizations/' + organization  + '/Projects/' + project + '/VariableGroups').catch((e) => console.error(e))
    }

    getAllVariableByVariableGroups(organization, project, variableGroupId) {
        return this.axiosInstance.get('Organizations/' + organization  + '/Projects/' + project + '/VariableGroups/' + variableGroupId).catch((e) => console.error(e))
    }
    postCompareVariableGroups(organization, variableGroups) {
        return this.axiosInstance.post('Organizations/' + organization  + '/VariableGroups/Compare', variableGroups).catch((e) => console.error(e))
    }
    postUpdateCreateVariableGroup(organization, variable) {
        return this.axiosInstance.post('Organizations/' + organization + '/VariableGroups', variable).catch((e) => console.error(e))
    }
}

export default new BackendService();
