import { defineStore } from "pinia";
import BackendService from "@/services/BackendService"

export const useOrganisationStore = defineStore('organisation', {
  state: () => ({
    loading: false,
    loadedOrganisations: [], // List of all organisations
    selectedOrganisationAccountId: undefined, // List of account ids of selected organisations
  }),
  getters: {
    isLoading () {
        return this.loading
    },
    getLoadedOrganisations () {
        return this.loadedOrganisations
    },
    getSelectedOrganisation () {
        return this.loadedOrganisations.find(organisation => organisation.accountId == this.selectedOrganisationAccountId)
    }
  },
  actions: {
    async fetchOrganisations() {
        this.loading = true
        await BackendService.getAllOrganisations()
        .then((e) =>{
            this.loadedOrganisations = e.data.value
            this.loading = false
        })
        .catch((e) => console.error(e)) // TO DO: Error handling
    },
    setSelectedOrganisation (organisation) {
        this.selectedOrganisationAccountId = organisation.accountId
    }
  }
})