import { createRouter, createWebHashHistory } from "vue-router";
import HomePage from "@/pages/HomePage"
import CreatePage from "@/pages/CreatePage"

const routes = [
    {
        path: '/', 
        component: HomePage
    },
    {
        path: '/create', 
        component: CreatePage
    },
]

export const router = createRouter(
    {
        history: createWebHashHistory(),
        routes,
    }
)