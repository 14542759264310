<template>
    <div class="h-full w-full">
        <div v-if="varStore.isLoading" class="absolute z-5 top-50 left-50">
            <i class="pi pi-spin pi-spinner text-8xl"></i>
        </div>
        <div 
            v-if="getVariableOfVariableGroups.length > 0"
            :class="
                {'overflow-auto': true, 
                'h-full': true, 
                'w-full': true,
                'opacity-50': varStore.isLoading
                }"
        >
            <table
                class=""
                cellspacing="0" cellpadding="0"
                style=""
            >
                <tr
                    class=""
                >
                    <th
                        class="border-bottom-2 surface-100 sticky z-2 top-0 left-0 overflow-hidden"
                    >
                        <div
                            class="flex flex-column align-items-center w-20rem py-2"
                        >
                            <header-cell
                                name="Key Name"
                            />
                            <search-cell
                                v-model:value="filterKeyName"
                                placeholder="Search variable"
                                :valid="filteredUniqueKeys.length > 0"
                            />
                        </div>
                    </th>
                    <th 
                        class="border-bottom-2 surface-100 sticky z-1 top-0 overflow-hidden"
                        v-for="variableGroups in getVariableOfVariableGroups" 
                        :key="variableGroups.name"
                    >
                        <div class="flex flex-column align-items-center w-15rem py-2">
                            <header-cell
                                :name="variableGroups.name"
                                :cloning="
                                () => {
                                    this.cloneVisible = true
                                    this.cloneVariableGroupProjectReference = variableGroups.variableGroupProjectReferences
                                }"
                                :description="variableGroups.description"
                            />
                            <!-- TO DO: change id -->
                            <search-cell
                                v-model:value="filters[variableGroups.name]"
                                :placeholder="'Search variable in' + variableGroups.name"
                                :valid="variableGroups.variables.length > 0"
                            />
                        </div>
                    </th>
                </tr>
                <tr
                    v-for="(uniqueKey, index) in filteredUniqueKeys"
                    :key="index"
                    class=""
                >
                    <td
                        class="surface-100 border-bottom-1 sticky z-0 left-0 overflow-hidden"
                    >
                        <div class="flex flex-column align-items-center w-20rem">
                            <p class="max-w-full" style="word-wrap: break-word;">
                                {{uniqueKey}}
                            </p>
                        </div>
                    </td>
                    <td
                        v-for="variableGroups in filteredVariables" 
                        :key="variableGroups.name"
                        class="border-bottom-1 overflow-hidden"
                    >
                        <div class="flex flex-column align-items-center">
                            <variable-cell
                                class=""
                                v-if="variableGroups.variables.find(res => res.key == uniqueKey)"
                                :value="variableGroups.variables.find(res => res.key == uniqueKey).value"
                                :variableKey="variableGroups.variables.find(res => res.key == uniqueKey).key"
                                :isReadOnly="variableGroups.variables.find(res => res.key == uniqueKey).isReadOnly"
                                :isSecret="variableGroups.variables.find(res => res.key == uniqueKey).isSecret"
                                :comparaisonStatus="whatIsMyComparaisonStatus(
                                    variableGroups.variables.find(res => res.key == uniqueKey).value,
                                    variableGroups.variables.find(res => res.key == uniqueKey).key,
                                    variableGroups.name)"
                                :variableGroupProjectReferences="variableGroups.variableGroupProjectReferences"
                            />

                            <add-variable-cell v-else
                                :variableGroupProjectReferences="variableGroups.variableGroupProjectReferences"
                                :variableKey="uniqueKey"
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td
                        class="surface-100 border-bottom-1 sticky z-0 left-0 overflow-hidden"
                    >
                        <div class="flex flex-column align-items-center w-20rem py-4">
                            <p-inputtext
                                v-model="new_key_placeholder"
                                placeholder="New key placeholder"
                            />
                        </div>
                    </td>
                    <td
                        v-for="variableGroups in filteredVariables" 
                        :key="variableGroups.name"
                        class="border-bottom-1 overflow-hidden"
                    >
                        <add-variable-cell
                            :variableGroupProjectReferences="variableGroups.variableGroupProjectReferences"
                            :variableKey="new_key_placeholder"
                        />
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <clone-dialogue v-model:modelVisible="cloneVisible" :variableGroupProjectReference="cloneVariableGroupProjectReference[0]"/>
</template>

<script>
import CloneDialogue from './CloneDialogue.vue'
import VariableCell from './VariableCell.vue'
import HeaderCell from './HeaderCell.vue'
import SearchCell from './SearchCell.vue'
import AddVariableCell from './AddVariableCell.vue'
import variableComparaison from '@/config/variableComparaison'

import { useVariableStore } from '@/store/variable.store'

export default {
    name: 'VariableTable',
    setup() {
        return {
            varStore: useVariableStore(),
        }
    },
    data() {
        return(
            {
                filters: [],
                filterKeyName: '',
                new_key_placeholder: '',
                cloneVisible: false,
                cloneVariableGroupProjectReference: {}
            }
        )
    },
    components: {
        "variable-cell": VariableCell,
        "header-cell": HeaderCell,
        "search-cell": SearchCell,
        "add-variable-cell": AddVariableCell,
        "clone-dialogue": CloneDialogue
    },
    computed: {
        filterActive () {
            for(let variableGroup of this.filteredVariables) {
                if(this.filters[variableGroup.name]) {
                    return true
                }
            }
            return false
        },
        getUniqueKeysNoFilterActive () {
            let uniqueKeys = []
            for(let variableGroup of this.filteredVariables) {
                for(let variable of variableGroup.variables) {
                    if(!uniqueKeys.includes(variable.key)){
                        uniqueKeys.push(variable.key)
                    }
                } 
            }
            return uniqueKeys
        },
        getUniqueKeysFilterActive () {
            let uniqueKeys = [ ...this.getUniqueKeysNoFilterActive ]
            for(let variableGroup of this.filteredVariables) {
                if (this.filters[variableGroup.name]){
                    for(let key of this.getUniqueKeysNoFilterActive) {
                        if(!variableGroup.variables.find((element) => element.key == key)){
                            const index = uniqueKeys.indexOf(key)
                            uniqueKeys.splice(index, 1);
                        }
                    } 
                }
            }
            return uniqueKeys
        },
        getUniqueKeys () {
            if(this.filterActive) {
                return this.getUniqueKeysFilterActive
            }else{
                return this.getUniqueKeysNoFilterActive
            }

        },
        filteredUniqueKeys () {
            return this.getUniqueKeys.filter(key => 
            {
                const filterValue = this.filterKeyName
                if(filterValue) {
                    return key.toString().toLowerCase().includes(filterValue.toLowerCase())
                }
                return key
            }
            )
        },
        getVariableOfVariableGroups () {
            return this.varStore.getVariableOfVariableGroups
        },
        filteredVariables() {
            return this.getVariableOfVariableGroups.map(
                (filteredVariableGroup) =>{
                const _filteredVariableGroup = { ...filteredVariableGroup}
                _filteredVariableGroup.variables = _filteredVariableGroup.variables.filter(
                    variable =>
                    {
                        const filterValue = this.filters[filteredVariableGroup.name]
                        if(filterValue && !variable.value) {
                            return false
                        }
                        if(filterValue) {
                            return variable.value.toString().toLowerCase().includes(filterValue.toLowerCase())
                        }
                        return variable
                    }
                )
                return _filteredVariableGroup
            }
            )
        }
    },
    methods: {
        filteredByVariableGroup(data, groupName) {
            let res = data.find(res => res.variableGroupProjectReference.name == groupName)
            if( !res ) {
                res = { 
                    "key": "API_PORT", 
                    "value": "No existing variable", 
                    "status": -1, 
                    "variableGroupProjectReference": 
                        { 
                            "id": undefined, 
                            "description": undefined, 
                            "name": undefined, 
                            "projectReference": { 
                                "id": undefined, 
                                "name": undefined
                            } 
                        } 
                    } 
            }
            return res
        },
        whatIsMyComparaisonStatus(value, key, groupVariableName) {
            if (value == "") {
                return variableComparaison.UNDEFINED
            }
            for(let variableGroup of this.filteredVariables) {
                if (variableGroup.name == groupVariableName) {
                    continue
                }
                let variable = variableGroup.variables.find(res => res.key == key)
                if(variable && variable.value == value) {
                    return variableComparaison.SAME
                }
            }
            return variableComparaison.DIFFERENT
        }
    },

}

</script>