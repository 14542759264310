<template>
    <div
        class="p-1 flex flex-column"
    >
        <div class="flex flex-row">
            <p class="m-1">{{ name }}</p>
            <span v-if="cloning">
                <i class="pi pi-clone m-1 cursor-pointer hover:text-color-secondary" :onclick="cloning"></i>
            </span> 
        </div>
        <div>
            <p class="text-left text-xs font-light">{{ description }}</p>
        </div>
    </div>
</template>

<script>

export default {
    name: "VariableCell",
    props: {
        name: String,
        description: String,
        cloning: Function
    }
}

</script>