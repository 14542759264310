<template>
    <div
        :class="{
            'bg-yellow-300': (comparaisonStatus == 1),
            'bg-red-300': (comparaisonStatus == 2),
        }"

        class="h-5rem w-15rem flex align-items-center justify-content-center"
    >
        <i v-if="isSecret" class="pi pi-lock"></i>
        <div v-else class="h-full w-full">
            <div
                class="flex flex-row justify-content-end mr-5"
            >
                <i v-if="isReadOnly" class="pi pi-eye"></i>
                <span v-else>
                    <span v-if="!isLoading">
                        <span v-if="!isEditing">
                        <i class="pi pi-pencil cursor-pointer hover:text-color-secondary" @click="startAddingVariable"></i>
                        </span>
                        <span v-else>
                            <i class="pi pi-check text-green-400 cursor-pointer hover:text-color-secondary" @click="confirmUpdatingVariable"></i>
                            <i class="pi pi-times text-red-400 cursor-pointer hover:text-color-secondary" @click="cancelAddingVariable"></i>
                        </span>
                    </span>
                    <span v-else>
                        <i class="pi pi-spin pi-spinner"></i>
                    </span>
                    <i class="pi pi-copy cursor-pointer hover:text-color-secondary" @click="copyVariableToClipboard"></i>
                </span>
            </div>
            <div
                class="flex flex-column w-full align-items-center"
            >
                <span v-if="isEditing">
                    <div class="w-full flex align-content-start">
                        <label for="input" class="text-left text-color-secondary text-xs">{{ value }}</label>
                    </div>
                    <div class="w-full">
                        <p-inputtext
                            id="input"
                            v-model="placeHolderValue"
                        />
                    </div>
                </span>
                <span  v-else>
                    <p class="max-w-full" style="word-wrap: break-word;">
                        {{ value }}
                    </p>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { useVariableStore } from '@/store/variable.store'
import { useOrganisationStore } from '@/store/organisation.store'

export default {
    name: "VariableCell",
    props: {
        value: String,
        variableKey: String,
        isReadOnly: Boolean,
        isSecret: Boolean,
        comparaisonStatus: Number,
        variableGroupProjectReferences: Object
    },
    setup() {
        return {
            orgStore: useOrganisationStore(),
            varStore: useVariableStore(),
        }
    },
    data() {
        return ({
            isLoading: false,
            isEditing: false,
            placeHolderValue: this.value
        })
    },
    // computed: {
    //     value: {
    //         get() {
    //             return this.$store.getters["api/getSelectedProjects"] 
    //         },
    //         set(projects) {
    //             this.$store.commit('api/setSelectedProjects', projects)
    //             this.$store.dispatch('api/fetchVariableGroups')
    //         }
    //     },
    // },
    // created(){
    //     this.placeHolderValue = this.value
    // },
    methods: {
        copyVariableToClipboard() {
            navigator.clipboard.writeText(this.value)
            this.$toast.add({ severity: 'info', summary: 'Copied variable', detail: 'Copied variable ' + this.variableKey + ' to clipboard. With value : "' + this.value + '"', life: 3000 })
        },
        startAddingVariable() {
            this.isEditing = true
        },
        cancelAddingVariable() {
            this.isEditing = false
            this.placeHolderValue = this.value
        },
        confirmUpdatingVariable() {
            this.$confirm.require({
                message: 'Are you sure you want to update the variable ' + this.variableKey + ' of the group of variable ' + this.variableGroupProjectReferences[0].name + ' ?' ,
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.isLoading = true
                    this.varStore.updateCreateVariableToVariableGroup( this.formatedVariable(), this.variableGroupProjectReferences, this.orgStore.getSelectedOrganisation.accountName)
                    .then(
                        () => {
                            this.cancelAddingVariable()
                            this.$toast.add({ severity: 'success', summary: 'Success Message', detail: 'Message Content', life: 3000 })
                            this.isLoading = false
                        }
                    )
                    .catch(
                        () => {
                            this.$toast.add({ severity: 'error', summary: 'Error Message', detail: 'Message Content', life: 3000 })
                            this.isLoading = false
                        }
                    )
                },
                reject: () => {
                    this.cancelAddingVariable()
                }
            })
        },
        switchMode() {
            this.isEditing = !this.isEditing
        },
        addVariable() {
            console.log(this.variableGroupProjectReferences)
        },
        formatedVariable() {
            return(
                {
                    key: this.variableKey,
                    value: this.placeHolderValue,
                    isEditing: this.isEditing,
                    isSecret: this.isSecret
                }
            )
        }
    }
}

</script>