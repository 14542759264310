<template>
    <div class="grid" id="navbar">
        <div class="col-12 m-0 h-6rem transition-duration-1000 transition-all transition-ease-in-out flex justify-content-center align-items-center z-0" id="welcome"
        >
                <h1 class="">Welcome to variableo <span v-if="authStore.isAuthentificated">{{ authStore.getAccount.name }}</span></h1>
        </div>
        <div class="col-12 grid bg-primary p-1 z-1">
            <div class="grid grid-nogutter lg:col-4 col-6">
                <div class="lg:col-4 col-6 max-h-6rem">
                    <logo-variableo></logo-variableo>
                </div>
                <div class="lg:col-8 col-6 grid grid-nogutter">
                    <div class="lg:col-4 col-12 flex align-items-center lg:justify-content-center">
                        <style-mode></style-mode>
                    </div>
                    <div class="lg:col-8 col-12 flex align-items-center lg:justify-content-center">
                        <login-page></login-page>
                    </div>
                </div>
            </div>
            <div class="grid grid-nogutter lg:col-8 col-6 flex align-items-center">
                <router-link class="lg:col-6 col-12 no-underline" to="/">
                    <div class="transition-colors text-color transition-duration-500 border-left-3 hover:bg-primary-reverse flex align-items-center">
                        <h2 class="m-0 ml-8 my-2">Home</h2> 
                        <i class="pi pi-search ml-3"></i>
                    </div>
                </router-link>
                <router-link class="lg:col-6 col-12 no-underline" to="/create">
                    <div class="transition-colors text-color transition-duration-500 border-left-3 hover:bg-primary-reverse flex align-items-center">
                        <h2 class="m-0 ml-8 my-2">Create</h2>
                        <i class="pi pi-plus-circle ml-3"></i>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import LogoVariableo from "@/components/LogoVariableo.vue"
import LoginPage from "./LoginPage.vue";
import StyleMode from "./StyleMode.vue";

import { useAuthentificationStore } from "@/store/authentification.store.js"

export default {    
    name: 'NavigationBar',
    components: {
        "logo-variableo": LogoVariableo,
        "login-page": LoginPage,
        "style-mode": StyleMode
    },
    setup () {
        return {
            authStore: useAuthentificationStore(),
        }
    },
    created () {
        window.addEventListener('wheel', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('wheel', this.handleScroll);        
    },
    methods: {
        handleScroll(event) {
            let scrollDown = (event.deltaY > 0) ? true : false
            let onNavbar = document.getElementById("navbar").parentNode.matches(":hover") ? true : false
            if (scrollDown) {
                document.getElementById("welcome").classList.remove("h-6rem", "opacity-100")
                document.getElementById("welcome").classList.add("h-1rem", "opacity-0")
            } else if (!scrollDown && onNavbar) {
                document.getElementById("welcome").classList.remove("h-1rem", "opacity-0")
                document.getElementById("welcome").classList.add("h-6rem", "opacity-100")
            }
        }
    }
}
</script>