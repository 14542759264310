<template>
    <div class="flex flex-none justify-content-center align-content-center">
        <div class="grid grid-nogutter text-color hover:text-color-secondary transition-colors transition-duration-500">
            <div 
                v-if="!authStore.isAuthentificated"
                @click="login"
                target="_blank"
                rel="noopener noreferrer"
                class="col-12 flex flex-row"
            >
                    <i class="pi pi-sign-in mr-2"></i>
                    <h4 class="m-0">login</h4>
            </div>
            <div 
                v-else
                @click="logout"
                target="_blank"
                rel="noopener noreferrer"
                class="col-12 flex flex-row"
            >
                    <h4 class="m-0">logout</h4>
                    <i class="pi pi-sign-out ml-2"></i>
            </div>
        </div>
    </div>
</template>

<script>
import { useAuthentificationStore } from "@/store/authentification.store.js"

export default {    
    name: 'LoginBar',
    setup () {
        return {
            authStore: useAuthentificationStore(),
        }
    },
    methods: {
        async login() {
            return await this.authStore.login()
            .then(() => {})
            .catch()
        },
        async logout() {
            return await this.authStore.logout()
            .then(() => {})
            .catch()
        }
    },
}

</script>