<template>
    <p-dialog 
        v-model:visible="visible" 
        :style="{ width: '50vw' }"
        :pt="{
            mask: {
                style: 'backdrop-filter: blur(2px)'
            }
        }"
        :draggable="false"
        modal
    >
        <template #header>
            <div>
                <p>Cloning the library "{{ variableGroupProjectReference.name }}" from the project "{{ variableGroupProjectReference.projectReference.name }}"</p>
            </div>
        </template>
        <div>
            <div class="grid grid-nogutter">
                <div class="col-12 p-1">
                    <p-dropdown
                        class="w-full"
                        v-model="selectedOrganisation"
                        :options="orgaStore.getLoadedOrganisations"
                        disabled
                        optionLabel="accountName"
                        placeholder="Select an Organisation"
                    />
                </div>
                <div class="col-6 p-1">
                    <p-dropdown
                        class="w-full"
                        v-model="selectedProject"
                        editable
                        filter
                        :loading="projectStore.isLoading"
                        :options="projectStore.getLoadedProjects"
                        :disabled="projectStore.isLoading || projectStore.getLoadedProjects < 1"
                        optionLabel="name"
                        placeholder="Select a project"
                    />
                </div>
                <div class="col-6 p-1">
                    <p-inputtext
                        v-model="newGroupName"
                        type="text"
                        placeholer="New Group Name..."
                        :disabled="!selectedProject"
                        :class="{
                            'p-invalid': !validNewProject,
                        }"
                        class="w-full"
                    />
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <div class="grid grid-nogutter">
                    <div class="col-6 grid">
                        <div class="col-6">
                            <p 
                                :class="validNewProject ? 'text-green-600 select-none cursor-pointer hover:text-green-300' : 'select-none text-green-900'" 
                                @click="validNewProject ? cloneProject() : this.$toast.add({ severity: 'error', summary: 'Error Message', detail: 'Be sure to select a project and a valid group name', life: 3000 })"
                                >Add</p>
                        </div>
                        <div class="col-6">
                            <p class="text-red-600 select-none cursor-pointer hover:text-red-400" @click="visible=false">Cancel</p>
                        </div>
                    </div>
                    <div class="col-6">
                        <div>
                            <p>
                              New feature, report any bug  encountered
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </p-dialog>
</template>

<script>
    import { useOrganisationStore } from '@/store/organisation.store'
    import { useProjectStore } from '@/store/project.store'
    import { useVariableGroupStore } from '@/store/variableGroup.store'
    import { useVariableStore } from '@/store/variable.store'
    
    export default {
        name: "CloneDialogue",
        setup() {
            return{
                orgaStore: useOrganisationStore(),
                projectStore: useProjectStore(),
                varGroupStore: useVariableGroupStore(),
                varStore: useVariableStore(),
            }
        },
        data() {
            return {
                projectLocation: undefined,
                newGroupName: "",
            }
        },
        props: {
            modelVisible: Boolean,
            variableGroupProjectReference: Object,
        },
        emits: ['update:modelVisible'],
        methods: {
            async cloneProject() {
                let groupName = this.newGroupName
                let clonedVar = this.varStore.getVariablesByVariableGroup(this.variableGroupProjectReference.name, this.variableGroupProjectReference.projectReference.id)
                this.varGroupStore.createVariableGroup(clonedVar, groupName, this.selectedProject, this.orgaStore.getSelectedOrganisation)
                this.visible=false
            }
        },
        computed: {
            validNewProject() {
                if(this.projectStore.isLoading || this.varGroupStore.isLoading || this.newGroupName == "") {
                    return false
                }
                if(this.selectedProject.name) {
                    let loadedVariableGroups = this.varGroupStore.getLoadedVariableGroupsByProjects
                    let projectExists = undefined!=loadedVariableGroups.find(project=>project.projectName==this.selectedProject.name).variableGroups.find(variableGroup => variableGroup.name.toLowerCase() == this.newGroupName.toLowerCase())
                    return projectExists ? false : true
                }
                return false
            },
            visible: {
                get() {
                    return this.modelVisible
                },
                set(value) {
                    this.$emit('update:modelVisible', value)
                }
            },
            selectedOrganisation:  {
                get() {
                    return this.orgaStore.getSelectedOrganisation
                }
            },
            selectedProject: {
                get() {
                    return this.projectLocation
                },
                set(project) {
                    this.projectLocation = project
                    this.varGroupStore.fetchVariableGroup(this.selectedOrganisation , this.projectLocation)
                }
            }
        },
    }
</script>