import { authConfig} from "@/config/index"
import { PublicClientApplication } from '@azure/msal-browser';

export const msal = new PublicClientApplication(authConfig)

class AuthentificationService {
    async initialize () {
        await msal.initialize();
    }

    async getAccount() {
        const accounts = await msal.getAllAccounts();
        if(accounts.length == 0){
            undefined
            msal.setActiveAccount(undefined)
        }
        msal.setActiveAccount(accounts[0])
        return accounts[0]
    }

    async login () {
        return msal
            .loginPopup({})
            .then(async() => {
                const accounts = await this.getAccount()
                return accounts;
            })
            .catch(error => {
                console.error(`error during authentication: ${error}`);
            });
    }

    async logout () {
        return msal
            .logoutPopup({})
            .then(async() => {
                const accounts = await this.getAccount()
                return accounts;
            })
            .catch(error => {
                console.error(`error while logging off: ${error}`);
            });
    }

    async getToken (scopes) {
        return await msal.acquireTokenSilent(scopes)
        .then((res)=>{
            return res.accessToken
        })
        .catch((e)=>console.error(e))
    }

}

export default new AuthentificationService();