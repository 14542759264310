import { appConfig } from "@/config/app";

export const authConfig = {
    auth: {
      clientId: appConfig.clientId,
      authority: 'https://login.microsoftonline.com/' + appConfig.tenantId,
      redirectUri: appConfig.redirectUri,
      postLogoutRedirectUri: appConfig.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
  }

export const authentificationParameters = {
  scopes: [appConfig.ressource + '/.default']
};