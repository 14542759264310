import { createApp } from 'vue'
import { router } from '@/router'
import { createPinia } from 'pinia'
import App from './App.vue'
import PrimeVue from "primevue/config";
import 'primeflex/primeflex.css';
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css';

import InputText from "primevue/inputtext";
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';


import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

const Pinia = createPinia()
const app = createApp(App)

app.use(Pinia)
app.use(PrimeVue);
app.use(router);
app.use(ToastService);
app.use(ConfirmationService)

app.component("p-inputtext", InputText);
app.component("p-dropdown", Dropdown);
app.component("p-multiselect", MultiSelect);
app.component("p-datatable", DataTable);
app.component("p-column", Column)
app.component("p-row", Row)
app.component("p-toast", Toast)
app.component("p-confirmdialog", ConfirmDialog)
app.component("p-dialog", Dialog)

app.mount('#app')


