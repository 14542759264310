<template>
    <div class="h-full w-full flex flex-column" v-if="authStore.isAuthentificated">
        <div class="flex-none">
            <libraries-selector class="flex-none"/>
        </div>
        <div class="flex-1 overflow-hidden min-w-full max-h-full p-5"
        >
            <variable-table/>
        </div>
    </div>
    <div class="" v-else>
        <h1>It seem's like you aren't logged in ?</h1>
    </div>
</template>

<script>
import LibrariesSelector from '@/components/home/LibrariesSelector'
import VariableTable from '@/components/home/VariableTable'

import { useAuthentificationStore } from "@/store/authentification.store.js"

export default {
    name: 'HomePage',
    components: {
        "libraries-selector": LibrariesSelector,
        "variable-table": VariableTable
    },
    setup () {
        return {
            authStore: useAuthentificationStore(),
        }
    }
}

</script>