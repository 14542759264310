<template>
  <div id="app" class="w-full h-screen overflow-hidden">
    <div class="flex flex-column w-full h-screen align-items-stretch">
      <div class="flex-none overflow-hidden">
        <nav-bar v-if="msal_initialized"></nav-bar>
      </div>
      <div class="flex-1 overflow-hidden">
        <router-view v-if="msal_initialized"></router-view>
      </div>
    </div>
    <p-toast/>
    <p-confirmdialog/>
  </div>
</template>

<script>
import NavigationBar from "@/pages/NavigationBar.vue"
import { useAuthentificationStore } from "@/store/authentification.store.js"

export default {
  name: 'App',
  setup() {
    return {
      authStore: useAuthentificationStore(),
    }
  },
  components: {
    "nav-bar": NavigationBar,
  },
  beforeMount() {
    
  },
  created() {
    this.authStore.initialize()
  },
  computed: {
    msal_initialized() {
      return this.authStore.msal_initialized
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-color);
}
</style>
