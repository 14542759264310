import { defineStore } from "pinia";
import AuthentificationService from "@/services/AuthentificationService";

export const useAuthentificationStore = defineStore('authentification', {
  state: () => ({
    account: undefined,
    msal_initialized: false
  }),
  getters: {
    isAuthentificated () {
        return this.account != undefined ? true : false
    },
    // TO DO: Throw error if msal is not initialized
    getAccount () {
        return this.account
    }

  }, 
  actions: {
    async initialize() {
        AuthentificationService.initialize().then(() => this.msal_initialized = true)
        .catch((e) => console.error(e))
        this.account = await AuthentificationService.getAccount()
    },
    // TO DO: Throw error if msal is not initialized
    async login() {
        await AuthentificationService.login().then((e) => this.account = e)
    },
    // TO DO: Throw error if msal is not initialized
    async logout() {
        await AuthentificationService.logout().then(() => this.account = undefined)
    }
  } 
}
)