<template>
    <div :class="isLight ? 'bg-white' : 'surface-ground'" class="overflow-hidden relative grid grid-nogutter border-0 border-2 border-round-2xl h-2rem w-4rem transition-duration-500 cursor-pointer"  @click="()=>{switchMode()}">
        <div :class="isLight ? 'translate-x-100' : 'translate-x-0'" class="absolute left-0 w-6 h-full transition-all transition-duration-500 bg-primary border-round-2xl">
        </div>
        <div class="col-6 flex align-items-center justify-content-center z-1">
            <i class="pi pi-moon text-color"></i>
        </div>
        <div class="col-6 flex align-items-center justify-content-center z-1">
            <i class="pi pi-sun text-color"></i>
        </div>
    </div>
</template>

<script>
const DARK_THEME = "arya-orange"
const LIGHT_THEME = "saga-orange"
export default {
    name: 'StyleMode',
    data() {
        return {
            currentTheme: String
        }
    },
    created() {
        this.currentTheme = LIGHT_THEME // The default theme is the one in the public/index.html
        if(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            this.$primevue.changeTheme(this.currentTheme, DARK_THEME, 'id-to-link', ()=>{})
            this.currentTheme = DARK_THEME
        }else{
            this.currentTheme = LIGHT_THEME
        }
    },
    methods: {
        switchMode() {
            let nextTheme;
            nextTheme = (this.currentTheme === LIGHT_THEME) ? DARK_THEME : LIGHT_THEME
            this.$primevue.changeTheme(this.currentTheme, nextTheme, 'id-to-link', ()=>{})
            this.currentTheme = nextTheme
        }
    },
    computed: {
        isLight () {
            return this.currentTheme === LIGHT_THEME ? true : false
        }
    }
}
</script>