import { defineStore } from "pinia";
import BackendService from "@/services/BackendService"

export const useVariableGroupStore = defineStore('variableGroup', {
  state: () => ({
    loading: false,
    loadedVariableGroupsByProjects: [], // List of all loaded projects
    selectedVariableGroups: [], // List of account ids of selected organisations
  }),
  getters: {
    isLoading () {
        return this.loading
    },
    getLoadedVariableGroupsByProjects () {
      return this.loadedVariableGroupsByProjects
    },
    getSelectedVariableGroups () {
        return this.selectedVariableGroups.map(
            (selectedVariableGroup) => 
                this.loadedVariableGroupsByProjects
                  .find(project => project.projectName == selectedVariableGroup.projectName)
                  .variableGroups.find(variableGroup => variableGroup.id == selectedVariableGroup.variableGroupId)
        )
    }
  },
  actions: {
    async fetchVariableGroups(selectedOrganisation, selectedProjects) {
        this.loading = true
        for(let project of selectedProjects) {
          // Check if variable groups are already loaded for project
          if(this.loadedVariableGroupsByProjects.find(x => x.projectId == project.id)==undefined) {
            await BackendService.getVariableGroupsByProject(selectedOrganisation.accountName, project.name)
              .then((res)=> {this.loadedVariableGroupsByProjects.push(
                {
                  organisationAccountName: selectedOrganisation.accountName,
                  organisationAccountId: selectedOrganisation.accountId,
                  projectId:project.id,
                  projectName:project.name,
                  variableGroups:res.data.value
                }
              )
              }
            ).catch((e) => console.error(e)) // TO DO: Error handling
          }
        }
        // Remove selectedVariableGroups of whom the project is not selected anymore
        this.selectedVariableGroups = this.selectedVariableGroups.filter(
          (selectedVariableGroup) => selectedProjects.find((project) => project.name == selectedVariableGroup.projectName)!=undefined
        )
        // Remove LoadedVariableGroupsByProjects that are not selected anymore
        this.loadedVariableGroupsByProjects = this.loadedVariableGroupsByProjects.filter(
          (VariableGroupsByProject) => 
            selectedProjects.find((project) => project.id == VariableGroupsByProject.projectId)!=undefined
        )
        this.loading = false
    },
    setSelectedProjects (variableGroups) {
        this.selectedVariableGroups = variableGroups.map((varGrp) => ({
            variableGroupId: varGrp.id,
            variableGroupName: varGrp.name,
            projectName: varGrp.variableGroupProjectReferences[0].projectReference.name
          }))
    },
    async fetchVariableGroup(selectedOrganisation, project){
      await BackendService.getVariableGroupsByProject(selectedOrganisation.accountName, project.name)
              .then((res)=> {
                let index = this.loadedVariableGroupsByProjects.findIndex(x => x.projectId == project.id)
                // Either replaces or adds the data to our loaded VariableGroups by Project
                index > -1 ? 
                this.loadedVariableGroupsByProjects.splice(index, 1, 
                  {
                    organisationAccountName: selectedOrganisation.accountName,
                    organisationAccountId: selectedOrganisation.accountId,
                    projectId:project.id,
                    projectName:project.name,
                    variableGroups:res.data.value
                  }
                ) : 
                this.loadedVariableGroupsByProjects.push(
                  {
                    organisationAccountName: selectedOrganisation.accountName,
                    organisationAccountId: selectedOrganisation.accountId,
                    projectId:project.id,
                    projectName:project.name,
                    variableGroups:res.data.value
                  }
                )
              }
              )
              .catch((e) => console.error(e)) // TO DO: Error handling
    },
    async createVariableGroup(variables, variableGroupName, projectReference, selectedOrganisation) {
      if (variables<1){
          throw "variables cannot be inferior to one"
      }
      let addedVariables = []
      let variableGroupProjectReference = {
        description:"",
        id: 0,
        name: variableGroupName,
        projectReference: projectReference
      }
      for (let variable of variables) {
          let temp =
          {
              key: variable.key,
              value: variable.value,
              isSecret: variable.isSecret ? true : false,
              isReadonly: variable.isReadonly ? true : false,
              status: 0,
              variableGroupProjectReference: variableGroupProjectReference
          }

          addedVariables.push(temp)
      }
      await BackendService.postUpdateCreateVariableGroup(selectedOrganisation.accountName, addedVariables)
      .then(() => {
        // Currently the BackendService doesn't return any id for our created variableGroup
        // Which is needed to perform any future action on our
        // Current work around is fetching all the VariableGroups for the project
        this.fetchVariableGroup(selectedOrganisation, projectReference)
      })
      .catch((e) => console.error(e)) // TO DO: Error handling
    }
  }
})