import { defineStore } from "pinia";
import BackendService from "@/services/BackendService"

export const useProjectStore = defineStore('project', {
  state: () => ({
    loading: false,
    loadedProjects: [], // List of all loaded projects
    selectedProjectsId: [], // List of account ids of selected organisations
  }),
  getters: {
    isLoading () {
        return this.loading
    },
    getLoadedProjects () {
        return this.loadedProjects
    },
    getSelectedProjects () {
        return this.selectedProjectsId.map(
            (id) => {
                return this.getLoadedProjects.find(project => project.id == id)
            }
        )
    }
  },
  actions: {
    async fetchProjects(accountName) {
        this.loading = true
        this.loadedProjects = []
        this.selectedProjectsId = []
        await BackendService.getProjectsByOrganisation(accountName)
        .then((e) =>{
            this.loadedProjects = e.data.value
            this.loading = false
        })
        .catch((e) => console.error(e)) // TO DO: Error handling
    },
    setSelectedProjects (projects) {
        this.selectedProjectsId = projects.map(project => project.id)
    }
  }
})